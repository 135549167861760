import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useSearch } from "../context/Search";
import Layout from "../componentes/Layout";
import { BASE_URL } from "../urls/url";
import axios from "axios";
import { useCart } from "../cart/hooks/useCart";
import toast from "react-hot-toast";
import AlertaProvincia from "./AlertaProvincia";
import DetallesProducto from "./DetallesProduct";
import MenuDesplegableCategoria from "../componentes/menuDesplegableCat";
const Search = () => {
  const [values, setValues] = useSearch();
  const params = useParams();
  const { addToCart, cart, deleteToCart } = useCart();
  const handleSubmit = async () => {
    try {
      const { data } = await axios.get(
        `${BASE_URL}/api/productos/producto-search/${params.id}`
      );
      setValues({ ...values, results: data.body });
    } catch (error) {
      setValues();
    }
  };

  const [provinciaSeleccionada, setProvinciaSeleccionada] = useState("");
  const [provinciaError, setProvinciaError] = useState(false);
  const [productoDetalle, setproductoDetalle] = useState();
  const [productModal, setProductModal] = useState({
    isOpen: false,
    product: null,
  });
  const openProductModal = (product) => {
    setProductModal({
      isOpen: true,
      product,
    });
  };
  useEffect(() => {
    setProvinciaSeleccionada(localStorage.getItem("provincia"));
    handleSubmit();
  }, [provinciaSeleccionada, provinciaError, productModal.isOpen, params]);

  return (
    <Layout tittle={"Search SendCuba"}>
      <section className="">
        <MenuDesplegableCategoria />
        <div className="text-center ">
          <h1 className="text-2xl">Resultados de la Busquedad</h1>
          <h6 className="text-xl text-slate-800">
            {values?.results.length < 1
              ? "No existen coincidencias"
              : `${values?.results.length} Productos encontrados`}
          </h6>
        </div>
        <div className="mx-auto grid max-w-6xl  grid-cols-2 gap-6 p-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 text-center">
          {values?.results?.map((p, index) => {
            const existsInCart = cart.some((item) => item.id === p.id);
            let producto = cart.find((item) => item.id === p.id);
            return (
              <article
                key={index}
                className="bg-gray-100 rounded-xl bg-red p-3 shadow-lg hover:shadow-xl hover:transform hover:scale-105 duration-300 "
              >
                <button onClick={() => openProductModal(p)}>
                  <div className="relative flex items-end overflow-hidden rounded-xl">
                    <img
                      src={`${BASE_URL}/api/productos/imagen/${p.id}`}
                      alt={p.nombre}
                    />
                  </div>
                </button>
                <div className="mt-1 p-2">
                  <button onClick={() => openProductModal(p)}>
                    <h2 className="text-slate-700">{p.nombre}</h2>
                    <p className="mt-1 text-sm text-slate-400">{p.provincia}</p>
                  </button>
                  <div className="mt-3 flex items-end justify-between">
                    <p className="text-lg font-bold text-blue-500">
                      ${p.precio}
                    </p>
                    {/* <div className="flex items-center space-x-1.5 rounded-lg bg-[#1C9D72]  px-4 py-1.5 text-white duration-100 hover:bg-[#1C9D72] "> */}
                      {!existsInCart ? (
                        <button
                        className="flex items-center space-x-1.5 rounded-lg bg-[#1C9D72]  px-4 py-1.5 text-white duration-100 hover:bg-[#1C9D72] "
                          onClick={() => {
                            if (provinciaSeleccionada === null) {
                              addToCart(p);
                              localStorage.setItem("provincia", p.provincia);
                              setTimeout(() => {
                                toast.success("Producto añadido");
                              });
                            } else {
                              if (provinciaSeleccionada === p.provincia) {
                                addToCart(p);
                                setTimeout(() => {
                                  toast.success("Producto añadido");
                                });
                              } else {
                                setProvinciaError(true);
                                setproductoDetalle(p);
                              }
                            }
                          }}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth="1.5"
                            stroke="currentColor"
                            className="h-4 w-4"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M2.25 3h1.386c.51 0 .955.343 1.087.835l.383 1.437M7.5 14.25a3 3 0 00-3 3h15.75m-12.75-3h11.218c1.121-2.3 2.1-4.684 2.924-7.138a60.114 60.114 0 00-16.536-1.84M7.5 14.25L5.106 5.272M6 20.25a.75.75 0 11-1.5 0 .75.75 0 011.5 0zm12.75 0a.75.75 0 11-1.5 0 .75.75 0 011.5 0z"
                            />
                          </svg>
                        </button>
                      ) : (
                        <div className="flex ml-1 rounded-lg bg-[#1C9D72] ">
                          {" "}
                          <button
                            className="flex items-center space-x-1.5 rounded-lg bg-[#1C9D72]  px-2 py-1.5 text-white duration-100 hover:bg-[#1C9D72] "
                            onClick={() => {
                              deleteToCart(p);
                              setTimeout(() => {
                                toast.error("Producto eliminado");
                              });
                            }}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width={16}
                              height={16}
                              fill="currentColor"
                              className="bi bi-trash3"
                              viewBox="0 0 16 16"
                            >
                              <path d="M6.5 1h3a.5.5 0 0 1 .5.5v1H6v-1a.5.5 0 0 1 .5-.5M11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3A1.5 1.5 0 0 0 5 1.5v1H1.5a.5.5 0 0 0 0 1h.538l.853 10.66A2 2 0 0 0 4.885 16h6.23a2 2 0 0 0 1.994-1.84l.853-10.66h.538a.5.5 0 0 0 0-1zm1.958 1-.846 10.58a1 1 0 0 1-.997.92h-6.23a1 1 0 0 1-.997-.92L3.042 3.5zm-7.487 1a.5.5 0 0 1 .528.47l.5 8.5a.5.5 0 0 1-.998.06L5 5.03a.5.5 0 0 1 .47-.53Zm5.058 0a.5.5 0 0 1 .47.53l-.5 8.5a.5.5 0 1 1-.998-.06l.5-8.5a.5.5 0 0 1 .528-.47M8 4.5a.5.5 0 0 1 .5.5v8.5a.5.5 0 0 1-1 0V5a.5.5 0 0 1 .5-.5" />
                            </svg>
                          </button>
                          <span className="px-1 text-lg ">
                            {producto.quantity}
                          </span>
                          <button
                            className="flex items-center space-x-1.5 rounded-lg  px-2 py-1.5 text-white duration-100 hover:bg-[#1C9D72] "
                            onClick={() => {
                              if (provinciaSeleccionada === null) {
                                addToCart(p);
                                localStorage.setItem("provincia", p.provincia);
                                setTimeout(() => {
                                  toast.success("Producto añadido");
                                });
                              } else {
                                if (provinciaSeleccionada === p.provincia) {
                                  addToCart(p);
                                  setTimeout(() => {
                                    toast.success("Producto añadido");
                                  });
                                } else {
                                  setProvinciaError(true);
                                  setproductoDetalle(p);
                                }
                              }
                            }}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width={16}
                              height={16}
                              fill="currentColor"
                              className="bi bi-plus-lg"
                              viewBox="0 0 16 16"
                            >
                              <path
                                fillRule="evenodd"
                                d="M8 2a.5.5 0 0 1 .5.5v5h5a.5.5 0 0 1 0 1h-5v5a.5.5 0 0 1-1 0v-5h-5a.5.5 0 0 1 0-1h5v-5A.5.5 0 0 1 8 2"
                              />
                            </svg>
                          </button>
                        </div>
                      )}
                    {/* </div> */}
                  </div>
                </div>
              </article>
            );
          })}
        </div>
      </section>
      <AlertaProvincia
        isOpen={provinciaError}
        producto={productoDetalle}
        // id={productoDetalle.id}
        onClose={() => setProvinciaError(false)}
      />
      {productModal.isOpen && (
        <DetallesProducto
          isOpen={productModal.product}
          producto={productModal.product}
          onClose={() => setProductModal({ isOpen: false })}
        />
      )}
    </Layout>
  );
};

export default Search;
