import toast from "react-hot-toast";
import { BASE_URL } from "../urls/url";
import { Modal } from "flowbite-react";
import { useEffect, useState } from "react";
import { useCart } from "../cart/hooks/useCart";
import AlertaProvincia from "./AlertaProvincia";

const DetallesProducto = ({ isOpen, id, producto, onClose }) => {
  const { addToCart, cart } = useCart();

  const [provinciaSeleccionada, setProvinciaSeleccionada] = useState("");
  const [provinciaError, setProvinciaError] = useState(false);
  const [productoDetalle, setproductoDetalle] = useState();
  useEffect(() => {
    setProvinciaSeleccionada(localStorage.getItem("provincia"));
  }, [provinciaSeleccionada, cart]);

  return (
    <>
      <Modal
        show={isOpen}
        onClose={onClose}
        position="center"
        className="pt-28 bg-opacity-30 bg-white "
      >
        {/* component */}

        <div className="fixed left-0 top-0 flex h-full mt-5 w-full items-center justify-center bg-black bg-opacity-50 py-10 ">
          <div className="max-h-full w-11/12 min-w-80 max-w-xl overflow-y-auto rounded-3xl sm:w-full bg-white ">
            <div className="w-full ">
              <div className="m-8 my-12 max-w-[400px] lg:max-w-[560px] mx-auto mt-8">
                <div className="mb-8">
                  <div className="flex justify-between items-center  pb-4 px-6">
                    <h2 className="text-2xl font-semibold">
                      {producto.nombre}
                    </h2>
                    <button
                      onClick={onClose}
                      className="text-gray-500 hover:text-gray-700 focus:outline-none"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width={24}
                        height={24}
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth={2}
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        className="feather feather-x"
                      >
                        <line x1={18} y1={6} x2={6} y2={18} />
                        <line x1={6} y1={6} x2={18} y2={18} />
                      </svg>
                    </button>
                  </div>
                  <div className="max-w-md w-full lg:flex lg:max-w-[560px] ">
                    <div className="relative flex items-end lg:items-center overflow-hidden rounded-3xl border-spacing-5 px-3  pb-4 lg:max-w-[240px]">
                      <img
                        className="rounded-xl"
                        src={`${BASE_URL}/api/productos/imagen/${producto.id}`}
                        alt={producto.nombre}
                      />
                    </div>
                    <div className="  bg-white rounded-b lg:rounded-b-none lg:rounded-r  flex flex-col justify-between leading-normal">
                      <div className="mb-8 ml-3">
                        <div className="flex justify-between items-center  pb-4 px-6">
                          <p className="text-sm text-grey-dark flex items-center">
                            <svg
                              className="text-grey w-3 h-3 mr-1"
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 24 24"
                              style={{ width: "24px", height: "24px" }}
                            >
                              <path d="M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10m0-7a3 3 0 1 1 0-6 3 3 0 0 1 0 6" />
                            </svg>
                            <p className="text-xl text-grey-dark flex items-center font-bold">
                              {producto.provincia}
                            </p>
                          </p>
                          <p className=" text-grey-dark flex items-center font-bold text-xl lg:pl-5">
                            ${producto.precio} USD
                          </p>
                        </div>

                        <p
                          className="text-grey-darker text-base pl-7"
                          dangerouslySetInnerHTML={{
                            __html: producto.descripcion.replace(
                              /\r\n/g,
                              "<br>"
                            ),
                          }}
                        ></p>
                      </div>
                      {/* <div className=" flex text-end"> */}
                      <button
                        className="flex  items-center  justify-evenly space-x-1.5 rounded-lg bg-[#1C9D72]  px-4 py-1.5 text-white duration-100 hover:bg-[#1C9D72]  w-1/2 mx-auto"
                        onClick={() => {
                          if (provinciaSeleccionada === null) {
                            addToCart(producto);
                            localStorage.setItem(
                              "provincia",
                              producto.provincia
                            );
                            setTimeout(() => {
                              toast.success("Producto añadido");
                            });
                          } else {
                            if (provinciaSeleccionada === producto.provincia) {
                              addToCart(producto);
                              setTimeout(() => {
                                toast.success("Producto añadido");
                              });
                            } else {
                              setProvinciaError(true);
                              setproductoDetalle(producto);
                            }
                          }
                        }}
                      >
                        Anadir al Carrito {"   "}
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth="1.5"
                          stroke="currentColor"
                          className="h-4 w-4 text-center"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M2.25 3h1.386c.51 0 .955.343 1.087.835l.383 1.437M7.5 14.25a3 3 0 00-3 3h15.75m-12.75-3h11.218c1.121-2.3 2.1-4.684 2.924-7.138a60.114 60.114 0 00-16.536-1.84M7.5 14.25L5.106 5.272M6 20.25a.75.75 0 11-1.5 0 .75.75 0 011.5 0zm12.75 0a.75.75 0 11-1.5 0 .75.75 0 011.5 0z"
                          />
                        </svg>
                      </button>
                      {/* </div> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
      <AlertaProvincia
        isOpen={provinciaError}
        producto={productoDetalle}
        // id={productoDetalle.id}
        onClose={() => setProvinciaError(false)}
      />
    </>
  );
};
export default DetallesProducto;
