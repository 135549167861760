import toast from "react-hot-toast";
import { Modal } from "flowbite-react";
import { useEffect, useState } from "react";
import { useCart } from "../cart/hooks/useCart";

const AlertaProvincia = ({ isOpen, id, producto, onClose }) => {
  const { addToCart, clearCart } = useCart();
  const [provinciaSeleccionada, setProvinciaSeleccionada] = useState("");
  useEffect(() => {
    setProvinciaSeleccionada(localStorage.getItem("provincia"));
  }, [provinciaSeleccionada]);

  return (
    <>
      <Modal
        show={isOpen}
        onClose={onClose}
        position="center"
        className="pt-28 bg-opacity-30 bg-white "
      >
        {/* component */}

        <div className="fixed left-0 top-0 flex h-full mt-5 w-full items-center justify-center bg-black bg-opacity-50 py-10 ">
          <div className="max-h-full w-11/12 min-w-80 max-w-xl overflow-y-auto rounded-3xl sm:w-full bg-white ">
            <div className="w-full ">
              <div className="m-8 my-12 max-w-[400px] lg:max-w-[560px] mx-auto mt-8">
                <div className="mb-8">
                  <div className="flex justify-between items-center  pb-4 px-6">
                    <h2 className="text-2xl font-semibold">Atención</h2>
                    <button
                      onClick={onClose}
                      className="text-gray-500 hover:text-gray-700 focus:outline-none"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width={24}
                        height={24}
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth={2}
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        className="feather feather-x"
                      >
                        <line x1={18} y1={6} x2={6} y2={18} />
                        <line x1={6} y1={6} x2={18} y2={18} />
                      </svg>
                    </button>
                  </div>
                  <div className="max-w-md w-full lg:flex lg:max-w-[560px] ">
                    <div className="  bg-white rounded-b lg:rounded-b-none lg:rounded-r  flex flex-col justify-between leading-normal">
                      <div className="mb-8 ml-3">
                        Va añadir un nuevo producto de una provincia distinta a
                        la de los productos que ya posee en el carrito. En caso
                        de que acepte, se eliminara los productos del carrito,
                        añadiendose este. Seleccione la opcion deseada:
                      </div>
                      <div className="flex justify-between items-center  pb-4 px-6">
                        <button
                          className="flex  items-center justify-evenly space-x-1.5 rounded-lg bg-[#1C9D72]  px-4 py-1.5 text-white duration-100 hover:bg-[#1C9D72] "
                          onClick={onClose}
                        >
                          Cancelar {"   "}
                        </button>
                        <button
                          className="flex  items-center justify-evenly space-x-1.5 rounded-lg bg-green-700 px-4 py-1.5 text-white duration-100 hover:bg-green-900"
                          onClick={() => {
                            onClose(false);
                            clearCart();
                            addToCart(producto);
                            setTimeout(() => {
                              toast.success("Producto añadido");
                            });
                            localStorage.setItem(
                              "provincia",
                              producto.provincia
                            );

                            window.location.reload();
                          }}
                        >
                          Aceptar
                        </button>
                      </div>

                      {/* </div> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};
export default AlertaProvincia;
