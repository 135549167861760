import { useState, useContext, createContext, useEffect } from "react";
import axios from "axios";
import { decodeToken } from "react-jwt";
const AuthContext = createContext();

const AuthProvider = ({ children }) => {
  const [auth, setAuth] = useState({});
  const [token, setToken] = useState("");

  //default axios
  axios.defaults.headers.common["Authorization"] = auth?.token;
  useEffect(() => {
    const data = localStorage.getItem("user");
    if (data) {
      const parseData = JSON.parse(data).token;
      setToken(parseData);
    }
    if (token) {
      const my = decodeToken(token);
      setAuth({
        // ...auth,
        user: my.user,
        email: my.email,
        telefono: my.telefono,
        admin: my.admin,
        token: token,
      });
    }
  }, [token]);

  return (
    <AuthContext.Provider value={[auth, setAuth]}>
      {children}
    </AuthContext.Provider>
  );
};

//custom hook
const useAuth = () => useContext(AuthContext);

export { useAuth, AuthProvider };
