import { useEffect, useState } from "react";
import axios from "axios";
import { BASE_URL } from "../../urls/url";
// import { BASE_URL } from "../Routes/global-env";

export default function useCategory() {
  const [categories, setCategories] = useState([]);

  //get cat
  const getCategories = async () => {
    try {
      const { data } = await axios.get(`${BASE_URL}/api/categorias`);
      setCategories(data?.body);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getCategories();
  }, []);
  return categories;
}
