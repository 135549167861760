import React from "react";
import Layout from "../componentes/Layout";
const Contact = () => {
  return (
    <Layout title={"Contacto SendCuba"}>
      <div className="row contactus">
        <div className="col-md-6">
          <img
            src="images/contact.jpg"
            alt="Contactus"
            style={{ width: "100%" }}
          />
        </div>
        <div className="col-md-4">
          <h1 className="bg-dark p-2 text-white text-center">CONTACT US </h1>
          <p className="text-justify mt-2">
            {" "}
            any query and info about product feel free to call anytime we 24x7
            vaialible
          </p>
          <p className="mt-3"> : infosendcuba@gmail.com</p>
          <p className="mt-3"> : +1 689 888 0135</p>
          <p className="mt-3"> : 1800-0000-0000(toll free)</p>
        </div>
      </div>
   </Layout>
  );
};

export default Contact;
