import { Routes, Route } from "react-router-dom";
import HomePage from "./pages/HomePage";
import Contact from "./pages/Contact";
// import Login from "./pages/Login";
// import Register from "./pages/Register";
import { CartProvider } from "./cart/context/cart";
import Search from "./pages/Search";
import Pagenotfound from "./pages/Pagenotfound";
import ProvinciaList from "./pages/ProvinciaList ";
import CategoriaList from "./pages/CategoriaList";
// import Prueba from "./pages/Prueba";
function App() {
  return (
    <CartProvider>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/contact" element={<Contact />} />
        {/* <Route path="/login" element={<Login />} />
        <Route path="/register" element={<Register />} /> */}
        <Route path="/search/:id" element={<Search />} />{" "}
        <Route path="/provincia/:keyword" element={<ProvinciaList />} />
        <Route path="*" element={<Pagenotfound />} />
        <Route path="/category/:categoria" element={<CategoriaList />} />
      </Routes>
    </CartProvider>
  );
}

export default App;
