import React, { useEffect, useState } from "react";
import useCategory from "../cart/hooks/useCategoys";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const MenuDesplegableCategoria = () => {
  const [isMobile, setIsMobile] = useState(false);
  const categories = useCategory();
  useEffect(() => {
    const mediaQuery = window.matchMedia("(max-width: 600px)");
    const handleResize = () => setIsMobile(mediaQuery.matches);
    mediaQuery.addListener(handleResize);
    handleResize();
    return () => mediaQuery.removeListener(handleResize);
  }, []);
  return (
    <>
      {isMobile ? (
        <div className="filtersMobil pt-1 ">
          {categories?.map((c, index) => (
            <a
              key={index}
              href={`/category/${c.nombre}`}
              className={classNames(
                "text-gray-900 hover:bg-gray-700 hover:text-white ml-5",
                "rounded-md px-3 py-2 text-sm font-medium border border-slate-600 rounded-l-lg hover:transform hover:scale-105 duration-300"
              )}
            >
              {c.nombre}
            </a>
          ))}
        </div>
      ) : (
        <div className="filters pt-1 ">
          {categories?.map((c, index) => (
            <a
              key={index}
              href={`/category/${c.nombre}`}
              className={classNames(
                "text-gray-900 hover:bg-gray-700 hover:text-white ml-5",
                "rounded-md px-3 py-2 text-sm font-medium border border-slate-600 rounded-l-lg hover:transform hover:scale-105 duration-300"
              )}
            >
              {c.nombre}
            </a>
          ))}
        </div>
      )}
    </>
  );
};

export default MenuDesplegableCategoria;
