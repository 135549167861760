import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { FiltersProvider } from "./cart/context/filters.jsx";
import { SearchProvider } from "./context/Search.js";
import { AuthProvider } from "./context/auth.js";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <AuthProvider>
    <BrowserRouter>
      <GoogleOAuthProvider clientId="31154102411-9kg3fee97c887osri5u668fkqgnuhp94.apps.googleusercontent.com">
        <SearchProvider>
          <FiltersProvider>
            <App />
          </FiltersProvider>
        </SearchProvider>
      </GoogleOAuthProvider>
    </BrowserRouter>
  </AuthProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
