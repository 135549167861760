import { useEffect, useState } from "react";
import Layout from "../componentes/Layout.js";
import Products from "../componentes/productsList.js";
import ModalSelectProvincia from "./ModalSelectProvincia.js";

const HomePage = () => {
  const [provinciaSeleccionada, setProvinciaSeleccionada] = useState("");
  const [ setProvinciaError] = useState(false);
  useEffect(() => {
    // getProducts();
    setProvinciaSeleccionada(localStorage.getItem("provincia"));
  }, [provinciaSeleccionada]);
  return (
    <>
      <ModalSelectProvincia
        isOpen={provinciaSeleccionada === null}
        onClose={() => setProvinciaError(false)}
      />
      <Layout title={'SendCuba '}>
        
        <Products />
      </Layout>
    </>
  );
};

export default HomePage;
