import React from "react";
import { useSearch } from "../context/Search.js";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";

const SearchInput = () => {
  // Search

  const params = useParams();
  const [values, setValues] = useSearch(params.keyword);
  const navigate = useNavigate();
  const handleSubmit = async (e) => {
    setValues({ ...values });
    navigate(`/search/${values.keyword}`);
  };

  return (
    <form role="search" className="w-full" onSubmit={handleSubmit}>
      <input
        className="w-full p-4 bg-black dark:bg-gray-100 shadow-none appearance-none rounded-2xl
      md:px-4 md:py-2 "
        style={{ marginRight: "16px" }}
        placeholder="    Search"
        type="search"
        value={values.keyword}
        onChange={(e) => setValues({ ...values, keyword: e.target.value })}
      />
    </form>
  );
};
export default SearchInput;
