import React, { useEffect, useState } from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import Footer from "./footer";
import { Toaster } from "react-hot-toast";
import Navbar from "./navbar";

const Layout = ({ children, title, descripcion, keywords, author }) => {
 
  const [provinciaSeleccionada, setProvinciaSeleccionada] = useState("");
  
  useEffect(() => {
    // getProducts();
    setProvinciaSeleccionada(localStorage.getItem("provincia"));
  }, [provinciaSeleccionada]);
  return (
    <HelmetProvider>
      <div>
        <Helmet>
          <meta charSet="utf-8" />
          <meta name="description" content={descripcion}></meta>
          <meta name="keywords" content={keywords}></meta>
          <meta name="author" content={author}></meta>
          <title>{title}</title>
        </Helmet>
        <Navbar />

        <main style={{ minHeight: "60vh", height: "100%" }}>
          <Toaster />

          {children}
        </main>
        <Footer />
      </div>
    </HelmetProvider>
  );
};

Layout.defaultProps = {
  title: "Store-shop Abgamez",
  descripcion: "Mercado Abgamez, Santa Clara, Villa Clara",
  keywords: "Store, mercado, shop, Abgamez",
  author: "Abraham Gamez",
};

export default Layout;
