import axios from "axios";
import toast from "react-hot-toast";
import { NavLink } from "react-router-dom";
import { BASE_URL } from "../urls/url";
import { Modal } from "flowbite-react";
import { useEffect, useState } from "react";

const ModalSelectProvincia = ({ isOpen, onClose }) => {
  const [provincia, setProvincia] = useState([]);
  const getAllProvincia = async () => {
    try {
      const { data } = await axios.get(`${BASE_URL}/api/provincias`);
      if (data.body) {
        setProvincia(data.body);
      }
    } catch (error) {
      console.log(error);
      toast.error("Error al conectarse a la Base de Datos de Provincias");
    }
  };

  useEffect(() => {
    getAllProvincia();
  }, []);

  return (
    <>
      <Modal
        show={isOpen}
        onClose={onClose}
        position="center"
        className="pt-28 bg-opacity-30 bg-white "
      >
        {/* component */}

        <div className="fixed left-0 top-0 flex h-full w-full items-center justify-center bg-black bg-opacity-50 py-10 ">
          <div className="max-h-full w-11/12 min-w-80 max-w-xl overflow-y-auto rounded-2xl sm:w-full bg-white ">
            <div className="w-full ">
              <div className="m-8 my-12 max-w-[400px] mx-auto">
                <div className="mb-8">
                  <h1 className="mb-4 text-3xl font-extrabold text-center">
                    Seleccione su Provincia
                  </h1>
                  <ul className="list-group list-group-flush">
                    {provincia?.map((c, index) => (
                      <li
                        className="list-group-item text-center mb-2"
                        key={index}
                      >
                        <NavLink
                          className="mb-4 text-xl font-semibold text-gray-900 hover:bg-gray-700 hover:text-white rounded-md px-3 py-2   "
                          to={`/provincia/${c.nombre}`}
                          style={{ borderRadius: "20px" }}
                          onClick={() => {
                            localStorage.setItem("provincia", c.nombre);
                            onClose();
                            // window.location.reload();
                          }}
                        >
                          {c.nombre}
                        </NavLink>
                        <br />
                      </li>
                    ))}
                  </ul>

                  <div className="space-y-4 text-center">
                    <button
                      onClick={() => {
                        setTimeout(() => {
                          toast.error("Debe seleccionar la provincia deseada");
                        }, 500);
                      }}
                      className="p-3 bg-slate-300 border rounded-full w-1/2  font-semibold text-xl"
                    >
                      Cerrar
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};
export default ModalSelectProvincia;
