import "./Cart.css";
import "./carrito.css";

import { useEffect, useId, useState } from "react";
import { CartIcon } from "./Icons.jsx";
import { useCart } from "../hooks/useCart.js";
import { Badge } from "antd";

import { XMarkIcon } from "@heroicons/react/24/outline";
function CartItem({ id, precio, nombre, quantity, removeFromCart }) {
  return (
    <div className="cart-product" key={id}>
      <div className="info-cart-product">
        <span className="cantidad-producto-carrito text-center">
          {parseInt(quantity)}
        </span>
        <p className="titulo-producto-carrito text-center">{nombre}</p>
        <span className="precio-producto-carrito text-center">
          ${precio} USD
        </span>
      </div>
      <button onClick={removeFromCart}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth="1.5"
          stroke="currentColor"
          className="icon-close ml-2"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M6 18L18 6M6 6l12 12"
          />
        </svg>
      </button>
    </div>
  );
}
function Total({ productos }) {
  const [total, setTotal] = useState(0);

  const getTotal = () => {
    let total = 0;

    if (productos.length > 0) {
      productos.forEach((p) => {
        total += p.precio * p.quantity;
      });
    }

    return total;
  };
  useEffect(() => {
    const total = getTotal();
    setTotal(total);
  }, [productos]);
  return (
    <div className="cart-total">
      <h3>Total:</h3>
      <span className="total-pagar">
        ${total}
        USD
      </span>
    </div>
  );
}

export function Cart() {

  const cartCheckboxId = useId();
  const { cart, clearCart, addToCart, removeFromCart } = useCart();
  const [showCart, setShowCart] = useState(false);

  const toggleCart = () => {
    setShowCart(!showCart);
  };

  const getTotal = () => {
    let total = 0;

    if (cart.length > 0) {
      cart.forEach((p) => {
        total += p.precio * p.quantity;
      });
    }

    return total;
  };

  const OK = () => {
    const form = [];

    for (let index = 0; index < cart.length; index++) {
      const element = cart[index];
      // form.push(`Nombre ${element.nombre}, con un precio ${element.precio} y la cantidad que deseo adquirir es de ${element.cantidad} productos`)
      form.push(`${parseInt(element.cantidad)} ${element.nombre} ($${parseInt(
        element.precio
      )}) c/u
    `);
    }
    // clearCart();
    const url = `https://api.whatsapp.com/send?phone=16898880135&text=Buenas,deseo comprar:
    ${form.join("")}
        *Total a pagar: ${getTotal()} USD*`




    const urlDev = encodeURI(url);
    // setBasicModal(!basicModal);
    window.open(urlDev);

    // navigate("/pedidos");
  };

  return (
    <>
      {cart?.length === 0 || cart === null ? (
        <>
          <label
            className="cart-button "
            htmlFor={cartCheckboxId}
            onClick={toggleCart}
          >
            <CartIcon />
          </label>
          <input id={cartCheckboxId} type="checkbox" hidden />
          <div
            className={`container-cart-products  
        
        ${showCart ? "" : "hidden-cart"} `}
            style={{ maxHeight: "75vh", overflow: "auto" }}
          >
            <div className="row-product" style={{ paddingLeft: "20px" }}>
              <ul>
                {cart.map((product) => (
                  <CartItem
                    key={product.id}
                    addToCart={() => addToCart(product)}
                    removeFromCart={() => removeFromCart(product)}
                    {...product}
                  />
                ))}
              </ul>

              {cart.length === 0 ? (
                <div className="row-product">
                  <div className="cart-product">
                    <div className="info-cart-product">
                      <p className="text-center text-xl">
                        El carrito esta vacio
                      </p>
                      <button onClick={toggleCart}>
                        <XMarkIcon
                          className="block h-6 w-6 items-end"
                          aria-hidden="true"
                        />
                      </button>
                    </div>
                  </div>
                </div>
              ) : (
                <>
                  <Total productos={cart} />

                  <button
                    className="btn-clear-all hover:bg-black"
                    onClick={() => clearCart()}
                    style={{ backgroundColor: "grey", textAlign: "center" }}
                  >
                    Vaciar carrito
                  </button>
                  <button
                    className="btn-comprar-all"
                    style={{
                      backgroundColor: "rgba(28, 157, 114)",
                      textAlign: "center",
                    }}
                    onClick={OK}
                  >
                    Comprar{" "}
                  </button>
                </>
              )}
            </div>
          </div>
        </>
      ) : (
        <Badge
          count={cart.reduce((total, item) => {
            return total + item.quantity;
          }, 0)}
          showZero
        >
          <label
            className="cart-button "
            htmlFor={cartCheckboxId}
            onClick={toggleCart}
          >
            <CartIcon />
          </label>
          <input id={cartCheckboxId} type="checkbox" hidden />

          <div
            className={`container-cart-products  
        
        ${showCart ? "" : "hidden-cart"} `}
            style={{ maxHeight: "75vh", overflow: "auto" }}
          >
            <div className="row-product" style={{ paddingLeft: "20px" }}>

              <div className="w-full text-right pr-3 pt-3 mb-[-15px]">
                <button
                  className="text-right "
                  onClick={toggleCart}>
                  <XMarkIcon
                    className="block h-6 w-6 items-end rounded-md"
                    aria-hidden="true"
                  />
                </button>
              </div>
              <ul>
                {cart.map((product) => (
                  <CartItem
                    key={product.id}
                    addToCart={() => addToCart(product)}
                    removeFromCart={() => removeFromCart(product)}
                    {...product}
                  />
                ))}
              </ul>

              {cart.length === 0 ? (
                <div className="row-product">
                  <div className="cart-product">
                    <div className="info-cart-product">
                      <p className="text-center text-xl">
                        El carrito esta vacio
                      </p>
                      <button onClick={toggleCart}>
                        <XMarkIcon
                          className="block h-6 w-6 items-end"
                          aria-hidden="true"
                        />
                      </button>
                    </div>
                  </div>
                </div>
              ) : (
                <>
                  <Total productos={cart} />

                  <button
                    className="btn-clear-all hover:bg-black"
                    onClick={() => clearCart()}
                    style={{ backgroundColor: "grey", textAlign: "center" }}
                  >
                    Vaciar carrito
                  </button>
                  <button
                    className="btn-comprar-all"
                    style={{
                      backgroundColor: "rgba(28, 157, 114)",
                      textAlign: "center",
                    }}
                    onClick={OK}
                  >
                    Comprar{" "}
                  </button>
                </>
              )}
            </div>
          </div>
        </Badge>
      )}
    </>
  );
}
