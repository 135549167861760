import { Fragment, useEffect, useState } from "react";
import { Disclosure, Menu, Transition } from "@headlessui/react";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";
import { Cart } from "../cart/components/Cart.jsx";
import SearchInput from "./SearchInput.js";
import { useAuth } from "../context/auth.js";
import toast from "react-hot-toast";
import { BASE_URL } from "../urls/url.js";
import axios from "axios";
import { useParams } from "react-router-dom";
import logo from "../imagenes/logo1.jpg";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function Navbar() {

  const [auth, setAuth] = useAuth();

  const handleLogout = () => {
    setAuth({
      ...auth,
      user: null,
      admin: "",
      token: "",
    });
    localStorage.removeItem("user");
    setTimeout(() => {
      toast.success("Logout Success");
    }, 500);
  };
  const [provinciaSelect, setprovinciaSelect] = useState([]);
  const getAllProvincia = async () => {
    try {
      const { data } = await axios.get(`${BASE_URL}/api/provincias`);
      if (data.body) {
        setprovinciaSelect(data.body);
      }
    } catch (error) {
      toast.error("Error al conectarse a la Base de Datos de Provincias");
    }
  };

  const [selectedProvincia, setSelectedProvincia] = useState("Provincias");

  const params = useParams();

  const [provinciaSeleccionada, setProvinciaSeleccionada] = useState("");
  useEffect(() => {
    getAllProvincia();
    if (params.keyword) {
      setSelectedProvincia(params.keyword);
    } else {
      setSelectedProvincia(localStorage.getItem("provincia"));
    }
  }, [params, provinciaSeleccionada]);
  useEffect(() => {
    // getProducts();
    setProvinciaSeleccionada(localStorage.getItem("provincia"));
  }, [provinciaSeleccionada]);
  return (
    <Disclosure as="nav" className="bg-gray-500 bg-opacity-15 shadow-2xl mb-16">
      {({ open }) => (
        <>
          <div
            className={classNames(
              provinciaSeleccionada === null
                ? "pointer-events-none mx-auto  px-2 sm:px-6 lg:px-8 fixed top-0 left-0 z-20 w-full  bg-white "
                : "mx-auto  px-2 sm:px-6 lg:px-8 fixed top-0 left-0 z-20 w-full  bg-white  "
            )}
          >
            <div className="relative flex h-16 items-center justify-between">
              <div className="absolute inset-y-0 left-0 flex items-center ">
                {/* Mobile menu button*/}
                <Disclosure.Button
                  className="relative inline-flex items-center justify-center rounded-md p-2 text-gray-400
                 hover:bg-gray-700 hover:text-white md:hidden
                focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                >
                  <span className="absolute -inset-0.5" />
                  <span className="sr-only">Open main menu</span>
                  {open ? (
                    <XMarkIcon className="block h-6 w-6" aria-hidden="true" />
                  ) : (
                    <Bars3Icon className="block h-6 w-6" aria-hidden="true" />
                  )}
                </Disclosure.Button>
              </div>
              <div className="flex flex-1 ml-14 sm:items-center sm:justify-center">
                <div className="flex flex-shrink-0 items-center">
                  <a
                    className="hover:transform hover:scale-105 duration-300"
                    href={
                      provinciaSeleccionada
                        ? `/provincia/${provinciaSeleccionada}`
                        : "/"
                    }
                  >
                    <img
                      className="h-14 w-auto"
                      src={logo}
                      alt="Logo SendCuba"
                    />
                  </a>
                </div>
                <div className=" w-full hidden md:ml-6 md:block">
                  <div className="flex space-x-4">
                    {/* {navigation.map((item) => (
                      <a
                        key={item.name}
                        href={item.href}
                        className={classNames(
                          item.current
                            ? "bg-gray-900 text-white"
                            : "text-gray-900 hover:bg-gray-700 hover:text-white",
                          "rounded-md px-3 py-2 text-sm font-medium "
                        )}
                        aria-current={item.current ? "page" : undefined}
                        onClick={() => setCurrent(item.name)}
                      >
                        {item.name}
                      </a>
                    ))} */}
                    {/* <Menu as="div" className="relative ml-3 w-1/2">
                      <div>
                        <Menu.Button className="text-gray-900 hover:bg-gray-700 hover:text-white rounded-md px-3 py-2 text-sm font-medium hover:transform hover:scale-105 duration-300">
                          <span className="absolute -inset-1.5" />
                          <span className="sr-only">Open user menu</span>
                          <div className="flex items-center ">
                            <svg
                              className="text-white w-3 h-3 mr-1"
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 24 24"
                              style={{ width: "24px", height: "24px" }}
                            >
                              <path d="M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10m0-7a3 3 0 1 1 0-6 3 3 0 0 1 0 6" />
                            </svg>

                            <span>
                              {selectedProvincia
                                ? selectedProvincia
                                : "Provincias"}
                            </span>
                          </div>
                        </Menu.Button>
                      </div>
                      <Transition
                        as={Fragment}
                        enter="transition ease-out duration-100"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95"
                      >
                        <Menu.Items className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                          {provinciaSelect?.map((c, index) => (
                            <Menu.Item key={index}>
                              {({ active }) => (
                                <a
                                  href={`/provincia/${c.nombre}`}
                                  className={classNames(
                                    active ? "bg-gray-100" : "",
                                    "block px-4 py-2 text-sm text-gray-700 hover:transform hover:scale-105 duration-300"
                                  )}
                                >
                                  {c.nombre}
                                </a>
                              )}
                            </Menu.Item>
                          ))}
                        </Menu.Items>
                      </Transition>
                    </Menu> */}
                    <SearchInput />
                  </div>
                </div>
              </div>

              <div className="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0 bg-white ">
                <Menu as="div" className="relative mx-3  text-center">
                  <div>
                    <Menu.Button className="text-gray-900 hover:bg-gray-700 hover:text-white rounded-md px-3 py-2 text-sm font-medium hover:transform hover:scale-105 duration-300">
                      <span className="absolute -inset-1.5" />
                      <span className="sr-only">Open user menu</span>
                      <div className="flex items-center ">
                        <svg
                          className="text-white w-3 h-3 mr-1"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                          style={{ width: "24px", height: "24px" }}
                        >
                          <path d="M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10m0-7a3 3 0 1 1 0-6 3 3 0 0 1 0 6" />
                        </svg>

                        <span>
                          {selectedProvincia ? selectedProvincia : "Provincias"}
                        </span>
                      </div>
                    </Menu.Button>
                  </div>
                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <Menu.Items className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                      {provinciaSelect?.map((c, index) => (
                        <Menu.Item key={index}>
                          {({ active }) => (
                            <a
                              href={`/provincia/${c.nombre}`}
                              className={classNames(
                                active ? "bg-gray-100" : "",
                                "block px-4 py-2 text-sm text-gray-700 hover:transform hover:scale-105 duration-300"
                              )}
                            >
                              {c.nombre}
                            </a>
                          )}
                        </Menu.Item>
                      ))}
                    </Menu.Items>
                  </Transition>
                </Menu>
                <Cart />

                {/* Profile dropdown */}

                {/*  */}
              </div>
            </div>
          </div>

          <Disclosure.Panel className="md:hidden rounded-2xl mb-[-58px]">
            <div className="space-y-1 px-2 pb-3 pt-20 ">
              {/* {navigation.map((item) => (
                <Disclosure.Button
                  key={item.name}
                  as="a"
                  href={item.href}
                  className={classNames(
                    item.current
                      ? "bg-gray-900 text-white"
                      : "text-gray-900 hover:bg-gray-700 hover:text-white",
                    "block rounded-md px-3 py-2 text-base font-medium"
                  )}
                  aria-current={item.current ? "page" : undefined}
                  onClick={() => setCurrent(item.name)}
                >
                  {item.name}
                </Disclosure.Button>
              ))} */}

              <SearchInput />
            </div>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
}
